import React from 'react';
import { connect } from 'react-redux';
import Intercom from 'react-intercom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { message } from 'antd';
import queryString from 'query-string';
import { LicenseManager } from 'ag-grid-enterprise';

import { withRouter } from '../containers/withRouter';
import { withTracker } from '../containers/withTracker';
import { isReceiver } from '../utils/UserUtils.ts';
import { hasPermission } from '../utils/Permissions';
import { withErrorBoundary } from './global/ErrorBoundary';
import { loadUserByToken, removeTokenAndUser, loadUserByOktaToken, logout } from '../actions/user';
import * as modalActions from '../actions/app/modal';
import { triggerUpdateCounter } from '../actions/catalogue/updateManager/update';
import {
  keys,
  exludedRequestPaths,
  goSystemApiRoot,
  goOnlySystemApiRoot,
} from '../constants/ParamountReactConstants';
import { MODAL_TYPES } from '../constants/ModalConstants';
import MainPage from '../pages/MainPage';
import LoginPage from '../pages/LoginPage';
import RegisterPage from '../pages/RegisterPage.tsx';
import ResetPasswordPage from '../pages/ResetPasswordPage.tsx';
import { notifyBugsnag } from '../actions/tools/BugsnagActions';
import { showUpgradePlanNotification } from '../actions/app/modal';

LicenseManager.setLicenseKey(keys.agTable.license_key);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.initInterceptors();
  }

  async componentDidMount() {
    if (location.pathname === '/sso') {
      const values = queryString.parse(this.props.location.search);
      const { sso_token } = values;
      if (sso_token) await this.props.dispatch(loadUserByOktaToken(sso_token));
      this.props.navigate('/');
    } else if (location.pathname === '/reset-password') {
      const values = queryString.parse(this.props.location.search);
      if (process.env.REACT_APP_STAGE === 'production' && values.pdm_env === 'beta') {
        this.setGoApiRoot();
      } else if (process.env.REACT_APP_STAGE === 'production' && values.pdm_env === 'test') {
        this.setGoOnlyApiRoot();
      }
    } else if (
      process.env.REACT_APP_STAGE === 'production' &&
      localStorage.getItem('goOnlyRedirected')
    ) {
      this.setGoOnlyApiRoot();
      this.props.dispatch(loadUserByToken());
    } else {
      this.props.dispatch(loadUserByToken()).then(response => {
        const user = response.value.data;

        if (
          process.env.REACT_APP_STAGE === 'production' &&
          hasPermission(user, 'running_on_go_api') &&
          !hasPermission(user, 'running_on_go_only_api')
        ) {
          this.setGoApiRoot();
          const apiRedirect = true;
          // @ts-ignore
          this.props.dispatch(loadUserByToken(apiRedirect));
        }

        if (
          process.env.REACT_APP_STAGE === 'production' &&
          hasPermission(user, 'running_on_go_only_api')
        ) {
          this.setGoOnlyApiRoot();
          const apiRedirect = true;
          this.props.dispatch(loadUserByToken(apiRedirect));
        }

        if (
          process.env.REACT_APP_API_ROOT?.includes('test-api') &&
          !hasPermission(user, 'running_on_go_only_api')
        ) {
          this.props.dispatch(logout());
        }
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user.id && this.props.user !== nextProps.user) {
      axios.defaults.headers.common['Paramount-Access-Token'] = nextProps.user.token;
    }
  }

  componentDidUpdate() {
    const { user, brands, location, isReceiver } = this.props;

    if (location.pathname === '/') {
      if (brands.length > 0 && user.id)
        this.props.navigate({
          pathname: '/brand/home',
          search: location.search,
        });
      if (isReceiver)
        this.props.navigate({
          pathname: '/available-brands/all-brands',
          search: location.search,
        });
    }
  }

  setGoApiRoot = () => {
    globalThis.apiRoot = goSystemApiRoot;
  };

  setGoOnlyApiRoot = () => {
    globalThis.apiRoot = goOnlySystemApiRoot;
  };

  checkUserOffline = () => {
    const { modalType } = this.props;
    if (!window.navigator.onLine && modalType !== MODAL_TYPES.USER_OFFLINE) {
      this.props.dispatch(modalActions.showModal(MODAL_TYPES.USER_OFFLINE));
    }
  };

  checkBackendDown = error => {
    const { modalType } = this.props;
    if (error.response && error.response.status === 502 && modalType !== MODAL_TYPES.BACKEND_DOWN) {
      this.props.dispatch(modalActions.showModal(MODAL_TYPES.BACKEND_DOWN));
    }
  };

  checkUnauthorizedUpdate = error => {
    const { t, isReceiver } = this.props;
    if (error.config) {
      const includedMethod = ['post', 'patch', 'put', 'delete'].includes(error.config.method);
      if (
        includedMethod &&
        error.config.url.includes('/items') &&
        !error.config.url.includes('/items/analyses') &&
        !error.config.url.includes('/items/search')
      ) {
        if (isReceiver) message.error(t('mainApp:changesNotSaved'));
        else this.props.dispatch(showUpgradePlanNotification());
      }
    }
  };

  initInterceptors = () => {
    axios.interceptors.response.use(
      response => {
        // eslint-disable-line
        this.checkRequestMethod(response.config.method, response.config.url);

        return response;
      },
      error => {
        this.checkUserOffline();
        this.checkBackendDown(error);
        if (this.props.isReceiver || (error.response && error.response.status === 404))
          this.checkUnauthorizedUpdate(error);

        // Do something with response error
        if (error.response && error.response.status === 401) {
          this.props.dispatch(removeTokenAndUser());
          globalThis.apiRoot = undefined;
        } else if (axios.isCancel(error)) {
          // ignore canceled calls
          return new Promise(() => {});
        } else {
          this.props.dispatch(
            notifyBugsnag(
              new Error(
                `${error.config.method.toUpperCase()} request call failed with status ${
                  error.response && error.response.status
                }: ${error.request.responseURL}`
              ),
              {
                metadata: {
                  request: error.request,
                  response: error.response,
                },
              }
            )
          );
        }
        return Promise.reject(error);
      }
    );
  };

  checkRequestMethod = (method, path = '') => {
    // don't trigger updates in cypress, analyses might run a throw error, because other test deleted the item already
    if (window.Cypress) return;

    const includedMethod = ['post', 'patch', 'put', 'delete'].includes(method);
    const exludedPath = exludedRequestPaths.some(entry => {
      if (entry.exception) {
        return (
          entry.method === method && path.includes(entry.path) && !path.includes(entry.exception)
        );
      }
      return entry.method === method && path.includes(entry.path);
    });

    if (includedMethod && !exludedPath) this.props.dispatch(triggerUpdateCounter());
  };

  getRouteView = () => {
    const { location, user, fetchingUser } = this.props;

    if (location.pathname.startsWith('/register')) {
      return <RegisterPage />;
    }
    if (location.pathname === '/reset-password') {
      const token = (queryString.parse(this.props.location.search) || {}).token;
      if (token) {
        return <ResetPasswordPage token={token} />;
      }
    }
    if (location.pathname === '/login/driv') {
      return <LoginPage key={1} okta />;
    }

    if (fetchingUser) {
      return null;
    }

    if (!user.id) {
      return <LoginPage key={1} />;
    }

    return <MainPage key={1} newVersion={this.props.newVersion} />;
  };

  getUserInfoForIntercom = () => {
    const { user, isReceiver } = this.props;
    if (user.id) {
      const name = `${user.first_name} ${user.last_name}`;
      const email = user.email;
      return {
        name,
        email,
        user_id: user.id,
        user_hash: user.intercom_user_hash,
        company: {
          id: user.parent_id || `r${user.receiver_id}`,
          name: user.account_name,
          company_account_type: isReceiver ? 'Receiver' : 'Brand',
        },
        account_type: isReceiver ? 'Receiver' : 'Brand',
      };
    }
    return {};
  };

  intercom = () => {
    const { mainMenuCollapsed } = this.props;
    const user = this.getUserInfoForIntercom();

    return (
      <Intercom
        key={2}
        appID={keys.intercom.app_id}
        {...user}
        alignment="left"
        horizontal_padding={mainMenuCollapsed ? 16 : 16}
        vertical_padding={mainMenuCollapsed ? 30 : 30}
      />
    );
  };

  view = () => {
    const stage = process.env.REACT_APP_STAGE;

    if (stage) {
      return [this.getRouteView(), this.intercom()];
    }
    return this.getRouteView();
  };

  render() {
    return this.view();
  }
}

App.propTypes = {
  user: PropTypes.object,
  error: PropTypes.object,
};

App.defaultProps = {
  user: {},
  error: null,
};

function mapStateToProps(state) {
  return {
    user: state.user.user,
    fetchingUser: state.user.fetchingUser,
    brands: state.parent.brands.brands,
    newVersion: state.app.global.newVersion,
    mainMenuCollapsed: state.app.global.mainMenuCollapsed,
    modalType: state.app.modal.modalType,
    isReceiver: state.user.user && isReceiver(state.user.user),
  };
}

export { App };
export default withRouter(
  withTracker(connect(mapStateToProps)(withErrorBoundary(withTranslation()(App))))
);
